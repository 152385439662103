import React, { forwardRef } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
}

const InputFwRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = (
  {
    ...props
  },
  ref
) => (
    // eslint-disable-next-line react/jsx-indent
    <div className="a-input">
      <input
        ref={ref}
        {...props}
      />
    </div>
  );

const Input = forwardRef(InputFwRef);

export default Input;
