const regexMessage = (regex: string) => {
  switch (regex) {
    case '/^\\d{8}$/':
      return 'có 8 chữ số';
    default:
      return regex;
  }
};

const returnValidateErrorMessages = (error: ValidateError, field: string) => {
  switch (error.code) {
    case 'minDigits':
      return `${field} phải có ít nhất ${error.details?.value} chữ số`;
    case 'maxDigits':
      return `${field} không vượt quá ${error.details?.value} chữ số`;
    case 'min':
      return `${field} phải lớn hơn hoặc bằng ${error.details?.value}`;
    case 'max':
      return `${field} phải nhỏ hơn hoặc bằng ${error.details?.value}`;
    case 'after':
      return `${field} phải sau ngày ${error.details?.value}`;
    case 'afterOrEqual':
      return `${field} phải sau hoặc bằng ngày ${error.details?.value}`;
    case 'before':
      return `${field} phải trước ngày ${error.details?.value}`;
    case 'beforeOrEqual':
      return `${field} phải trước hoặc bằng ngày ${error.details?.value}`;
    case 'digits':
      return `${field} phải có ${error.details?.value} chữ số`;
    case 'lt':
      return `${field} phải nhỏ hơn ${error.details?.value}`;
    case 'lte':
      return `${field} phải nhỏ hơn hoặc bằng ${error.details?.value}`;
    case 'gt':
      return `${field} phải lớn hơn ${error.details?.value}`;
    case 'gte':
      return `${field} phải lớn hơn hoặc bằng ${error.details?.value}`;
    case 'regex':
      return `${field} không đúng định dạng. Giá trị phải ${regexMessage(error.details?.value || '')}`;
    case 'notRegex':
      return `${field} không đúng định dạng. Giá trị phải ${regexMessage(error.details?.value || '')}`;
    case 'size':
      return `${field} phải có độ dài ${error.details?.value}`;
    case 'unique':
      return `${field} đã tồn tại`;
    case 'same':
      return `${field} trùng với ${error.details?.field}`;
    case 'different':
      return `${field} phải khác ${error.details?.field}`;
    case 'dateFormat':
    case 'in':
    case 'notIn':
    case 'mimes':
    case 'mimetypes':
      return `${field} phải thuộc: ${error.details?.values?.join(', ')}`;
    case 'bettween':
      return `${field} phải thuộc khoảng ${error.details?.from} - ${error.details?.to}`;
    case 'digitsBetween':
      return `${field} phải có độ dài từ ${error.details?.from} - ${error.details?.to}`;
    default:
      return `${field} không hợp lệ`;
  }
};

export default returnValidateErrorMessages;
